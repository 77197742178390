.container,
.containerProfile {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-left: 30px;
  padding-right: 30px;
}

.containerProfile {
  margin-top: 64px;
}

.title,
.titleProfile {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  margin: 0;
  margin-bottom: 8px;
  margin-top: 24px;
  color: #393e46;
  text-align: center;
}

.titleProfile {
  font-size: 28px;
  line-height: 36px;
}

.subTitle,
.subTitleProfile {
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
  margin: 0;
  color: #393e46;
  margin-bottom: 40px;
}

.subTitleProfile {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.icon {
  height: 150px;
  margin-top: 32px;
}

@media (max-width: 500px) {
  .icon {
    width: 100%;
    height: auto;
  }
  .title {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    text-align: center;
  }
}
