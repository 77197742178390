.container {
  width: 100%;
  max-width: 464px;
  box-shadow: 18px 19px 64.8px 0px #F1F1F1;
  padding: 24px 32px 32px 32px;
  border-radius: 25px;
  background: #FFFFFF;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.title {
  font-size: 28px;
  font-weight: 500;
  line-height: 36px;
  margin: 0;
  margin-bottom: 20px;
  color: var(--main-color-text-title);
  opacity: 0.9;
}

.titleBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.backBtn {
  position: absolute;
  top: -70px;
  left: 0;
}

.btn {
  height: 56px;
}

.part {
  color: var(--main-color-text-secondary-unactive);
}

.term {
  margin: 0;
  margin-top: 40px;
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--main-color-text-secondary);
}

.link {
  margin: 0;
  margin-top: 40px;
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--brand-color-primary);
  cursor: pointer;
}

.card {
  background: transparent !important;
  padding: 0px !important;
}

.error {
  margin: 0;
  margin-bottom: 12px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: var(--error-color-icon);
}

@media (max-width: 580px) {
  .container {
    box-shadow: none;
    background: transparent;
    margin-top: 0px;
    width: auto;
    padding: 24px 16px 32px 16px;
  }
  .backBtn {
    position: static;
    margin-bottom: 20px;
  }
  .title {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
  }
}
