.ant-picker-input > input {
  font-size: 17px !important;
  font-weight: 500 !important;
  line-height: 20px !important;
}

.ant-picker-input > input::placeholder {
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  color: var(--main-color-text-secondary) !important;
}

.ant-picker-outlined:focus-within {
  box-shadow: none !important;
}

.ant-picker-cell-inner::before {
  border: 1px solid var(--brand-color-primary) !important;
}

.ant-picker-now-btn {
  color: var(--brand-color-primary) !important;
}

.ant-picker-cell-selected .ant-picker-cell-inner {
  background: var(--brand-color-primary) !important;
}

.ant-picker-month-btn:hover {
  color: var(--brand-color-primary) !important;
}

.ant-picker-year-btn:hover {
  color: var(--brand-color-primary) !important;
}

.ant-picker-suffix {
  cursor: pointer;
  pointer-events: auto !important;
}

@media (max-width: 600px) {
  .ant-picker-input > input::placeholder {
    font-weight: 400 !important;
  }
}
