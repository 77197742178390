.title {
  color: var(--main-color-text-title);
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
}
.confirmationTitle {
  color: var(--main-color-text-title);
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
  text-align: center;
}

.subTitle {
  margin: 0;
  color: var(--main-color-notification-description);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;

  margin-bottom: 20px;
}

.description {
  margin: 0;
  margin-top: 20px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  color: var(--main-color-text-title);
  margin-bottom: 14px;
}

.subDescription {
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: var(--main-color-text-title);
  margin-bottom: 4px;
}

.smallTitle {
  margin: 0;
  margin-top: 20px;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: var(--main-color-text-title);
}

.smallDescription {
  margin: 0;
  margin-top: 12px;
  margin-bottom: 4px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: var(--main-color-text-title);
}

.limit {
  margin: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: var(--main-color-notification-description);
  margin-bottom: 8px;
}

.error {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  color: var(--error-color-icon);
  margin: 0;
}

.btn {
  height: 56px;
}

.input {
  border-radius: 16px;
}

.input > div {
  border: none;
  height: 64px;
}
