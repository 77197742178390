.primary {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 10px;
  border-radius: 13px;
  color: var(--main-color-white);
  background-color: var(--brand-color-primary);
  outline: none;
  border: none;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;
  gap: 8px;
  height: 56px;
}

.primary:disabled {
  color: var(--main-color-text-secondary-unactive);
  background-color: var(--brand-color-disabled);
  cursor: auto;
}

.primary:active {
  color: var(--brand-color-primary);
  background-color: var(--main-color-white);
}

.secondary, .dangerous {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 10px;
  border-radius: 13px;
  outline: none;
  border: none;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;
  gap: 8px;
  height: 56px;
}

.secondary {
  color: var(--brand-color-primary);
  background-color: var(--main-color-bg-widgets);
}

.dangerous {
  color: var(--error-color-primary);
  background-color: var(--error-color-notification);
}

.secondary:disabled {
  color: var(--main-color-text-secondary-unactive);
  background-color: var(--main-color-bg-widgets);
  cursor: auto;
}

.dangerous:disabled {
  color: var(--main-color-text-secondary-unactive);
  background-color: var(--main-color-bg-widgets);
  cursor: auto;
}

.secondary:active {
  color: var(--brand-color-primary);
  background-color: var(--main-color-white);
}

.dangerous:active {
  color: var(--error-color-icon);
  background-color: var(--error-color-notification-light);
}

.text {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: var(--brand-color-primary);
  outline: none;
  border: none;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;
  gap: 8px;
}

.text:disabled {
  color: var(--main-color-text-secondary-unactive);
  cursor: auto;
}

.text:active {
  color: var(--brand-color-unactive);
}

@media (min-width: 800px) {
  .primary:hover {
    color: var(--brand-color-primary);
    background-color: var(--brand-color-hover);
  }
  
  .secondary:hover {
    color: var(--brand-color-primary);
    background-color: var(--brand-color-disabled);
  }

  .dangerous:hover {
    color: var(--error-color-icon);
    background-color: var(--error-color-notification-light);
  }
  
  .text:hover {
    color: var(--brand-color-unactive);
  }
}