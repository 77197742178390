.notification {
  display: flex;
  align-items: center;
  margin-right: 8px;
  cursor: pointer;

  position: relative;

  span {
    position: absolute;

    background-color: var(--secondary-color-1);
    width: 8px;
    height: 8px;
    border-radius: 100px;

    top: 0;
    right: 0;
  }
}
