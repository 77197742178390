.container {
  padding: 16px 30px;
}

.title {
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  color: var(--main-color-notification-title);
  margin: 0;
  margin-bottom: 12px;
}

.description {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: var(--main-color-notification-title);
  margin: 0;
}

.items, .buttons {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.items {
  margin-top: 28px;
  margin-bottom: 56px;
}

.item {
  display: flex;
  align-items: center;
  padding: 12px;
  gap: 12px;
  border-radius: 15px;
  background: var(--main-color-bg-widgets);
}

.number {
  color: var(--brand-color-primary);
}

.loaderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.noDeviceTitle {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  color: var(--main-color-notification-title);
  margin: 0;
  margin-top: 22px;
  margin-bottom: 8px;
}

.noDeviceDescription {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: var(--main-color-notification-title);
  margin: 0;
  margin-bottom: 68px;
}

.notificationIcon {
  width: 150px;
  height: 150px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}