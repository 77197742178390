.container {
  width: 100%;
  max-width: 528px;
  box-shadow: 18px 19px 64.8px 0px #F1F1F1;
  padding: 24px 32px 32px 32px;
  border-radius: 25px;
  background: #FFFFFF;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.title {
  font-size: 28px;
  font-weight: 500;
  line-height: 36px;
  margin: 0;
  color: var(--main-color-text-title);
  opacity: 0.9;
  margin: 0;
  margin-top: 12px;
  text-align: center;
}

.description {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: var(--main-color-text-subtitle);
  margin: 0;
  margin-top: 12px;
  text-align: center;
}

.titleBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 40px;
}

.backBtn {
  position: absolute;
  top: -70px;
  left: 0;
}

.btn {
  height: 56px;
}

.link {
  margin-left: auto;
  margin-right: auto;
}

.icon {
  width: 150px;
}

@media (max-width: 580px) {
  .container {
    box-shadow: none;
    background: transparent;
    margin-top: 0px;
    width: auto;
    padding: 24px 16px 32px 16px;
  }
  .backBtn {
    position: static;
    margin-bottom: 20px;
  }
  .title {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
  }
}