.iconExitContainer {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 767px) {
  .iconExitContainer {
    display: none;
  }
}
