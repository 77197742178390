.header {
  display: flex;
  align-items: center;
  padding-bottom: 12px;
}

.backButton {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 8px;
}

.title {
  flex-grow: 1;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  text-align: center;
  color: var(--main-color-text-title);
}
