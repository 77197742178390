.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
}

.title {
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  text-align: center;
  color: var(--main-color-text-title);
  margin: 0;
  margin-bottom: 8px;
  margin-top: 22px;
}

.description {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: var(--main-color-text-title);
  margin: 0;
  margin-bottom: 110px;
}