.main_container {
  display: grid;
  grid-template-columns: 1fr 1fr;

  /* padding: 0 16px; */

  /* @media (min-width: 588px) { */
  @media (min-width: 780px) {
    /* padding: 0 98px; */

    grid-template-columns: repeat(6, 1fr);
    column-gap: 16px;
  }

  @media (min-width: 980px) {
    width: 900px;
    column-gap: 0;
    grid-template-columns: 150px 20px 150px 32px repeat(5, 78px 16px) 78px;
  }

  @media (min-width: 1440px) {
    width: 1260px;
    grid-template-columns: 178px 24px 178px 52px repeat(5, 118px 24px) 118px;
  }
}

.fractions {
  display: grid;
}
