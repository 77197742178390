.containerInitial {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.contentInitial,
.containerSuccess {
  padding-left: 32px;
  padding-right: 32px;

  position: relative;
}

.title {
  margin: 0;
  margin-top: 23px;
  margin-bottom: 18px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  color: var(--main-color-text-title);
}

.items {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.btn {
  max-width: calc(100% - 64px);
  margin: 0 32px;
  margin-top: 44px;
}

.item {
  display: flex;
  align-items: center;
  padding: 12px;
  gap: 12px;
  border-radius: 15px;
  background: var(--main-color-bg-widgets);
}

.itemTitle {
  margin: 0;
  margin-bottom: 4px;
  color: var(--main-color-text-title);
  font-size: 17px;
  font-weight: 500;
  line-height: 20px;
}

.itemDescription {
  margin: 0;
  color: var(--main-color-notification-description);
  font-size: 13px;
  font-weight: 400;
  line-height: 14px;
}

.containerSuccess {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin-top: 70px;
}

.titleSuccess {
  margin: 0;
  font-size: 28px;
  font-weight: 500;
  line-height: 36px;
  color: var(--main-color-notification-title);
  margin-top: 22px;
  margin-bottom: 8px;
}

.descriptionSuccess {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  color: var(--main-color-notification-title);
  margin-bottom: 52px;
}

@media (max-width: 500px) {
  .contentInitial,
  .containerSuccess {
    padding-left: 16px;
    padding-right: 16px;
  }
  .containerSuccess {
    height: 100%;
    margin-top: 0;
  }
  .title {
    margin-top: 0px;
  }
  .btn {
    margin: 0;
  }
  .btn,
  .btnSuccess {
    position: absolute;
    bottom: 30px;
    left: 16px;
    max-width: calc(100% - 32px);
  }
  .titleSuccess {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
  }
  .descriptionSuccess {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }
}
