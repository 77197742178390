.wrapper {
  display: flex;
  align-items: center;

  :global .react-datepicker {
    position: static;
    margin-top: 0;
    background-color: var(--main-color-white);
    box-shadow: 18px 19px 64.8px 0px #d4d5d4;
  }

  :global .react-datepicker__current-month {
    height: unset;
    padding: 0;
    margin: 4px;
  }

  :global .react-datepicker__navigation {
    top: 13px;
  }

  :global .react-datepicker__current-month,
  :global .react-datepicker__day-names,
  :global .react-datepicker__day-name {
    background-color: var(--main-color-white);
  }

  :global .react-datepicker__day {
    cursor: pointer;
    color: var(--main-color-text-title);
    outline-color: unset;

    &:hover {
      color: white;
    }

    &:focus-visible {
      outline: none;
    }
  }

  :global .react-datepicker__day--keyboard-selected {
    color: var(--brand-color-primary);
    background-color: unset;
    border: unset;
    font-weight: 500;

    &:hover {
      color: var(--brand-color-unactive);
    }
  }

  :global .react-datepicker__day--today {
    background-color: var(--main-color-white);
    font-weight: bold;
  }

  :global .react-datepicker__day--selected {
    background-color: var(--main-color-white);
    border-color: var(--brand-color-unactive);
    border: 1.5px solid var(--brand-color-unactive);
    color: var(--brand-color-primary);
    border-radius: 40px;

    &:hover {
      color: var(--main-color-white);
    }
  }

  :global .react-datepicker__day-name {
    color: var(--main-color-text-title);
  }

  :global .react-datepicker__day--outside-month {
    color: var(--main-color-text-secondary-unactive);
  }

  :global .react-datepicker__day--in-selecting-range,
  :global .react-datepicker__day--in-range {
    background-color: var(--brand-color-disabled) !important;
    color: var(--brand-color-primary);
    border-radius: 0;
  }

  :global .react-datepicker__day--range-start,
  :global .react-datepicker__day--selecting-range-start {
    background-color: var(--brand-color-primary) !important;
    color: var(--main-color-white);
    border-radius: 40px;

    &:hover {
      color: var(--main-color-white);
    }
  }

  :global .react-datepicker__day--range-end,
  :global .react-datepicker__day--selecting-range-end {
    background-color: var(--main-color-white) !important;
    border-color: var(--brand-color-unactive);
    border: 1.5px solid var(--brand-color-unactive);
    color: var(--brand-color-primary);
    border-radius: 40px;

    &:hover {
      color: var(--brand-color-primary);
    }
  }

  :global .react-datepicker__day--disabled {
    color: var(--brand-color-disabled);
    pointer-events: none;
  }

  @media screen and (max-width: 365px) {
    :global .react-datepicker {
      box-sizing: border-box;
      width: 300px;
    }
  }

  @media screen and (max-width: 340px) {
    :global .react-datepicker {
      width: 280px;
    }

    :global .react-datepicker__day {
      width: 35px;
      height: 35px;
    }

    :global .react-datepicker__day--range-end,
    :global .react-datepicker__day--selecting-range-end,
    :global .react-datepicker__day--range-start,
    :global .react-datepicker__day--selecting-range-start {
      border-radius: 35px;
    }
  }
}
