.tabsContainer {
  display: flex;
  gap: 30px;
}

.tab {
  font-weight: 400;
  color: #8b8c88;
  font-size: 16px;
  padding: 12px 16px;
  border-radius: 16px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  background-color: transparent;
  border: none;
  outline: none;
  text-align: center;
}

.tab.selected {
  background-color: rgba(255, 255, 255, 0.41);
  color: #739b67;
  font-weight: 500;
}
