.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1268px;
  padding-top: 55px;
  padding-bottom: 43px;
  margin-left: auto;
  margin-right: auto;
}

.content {
  display: flex;
  justify-content: space-between;
  row-gap: 32px;
}

.infoBlock {
  display: flex;
  justify-content: space-between;
}

.title {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: var(--main-color-text-title);
  margin: 0;
  margin-bottom: 32px;
}

.description {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.005em;
  color: var(--main-color-text-secondary);
  margin: 0;
}

.active {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: var(--brand-color-primary);
  margin: 0;
  cursor: pointer;
}

.line {
  height: 1px;
  width: 100%;
  background-color: var(--main-color-text-secondary-unactive);
  margin-top: 70px;
  margin-bottom: 70px;
}

.info {
  font-size: 17px;
  font-weight: 400;
  line-height: 21px;
  color: var(--main-color-text-secondary);
  margin: 0;
}

.logo {
  width: 157px;
  cursor: pointer;
}

.block {
  margin-left: 30px;
  margin-right: 30px;
}

@media (max-width: 1325px) {
  .container {
    margin-left: 16px;
    margin-right: 16px;
    padding-top: 43px;
    width: auto;
  }

  .title.invariant {
    padding: 12px 0;
    margin: 0;
  }
}

@media (max-width: 800px) {
  .block {
    margin-left: 0px;
    margin-right: 0px;
  }
  .content {
    flex-direction: column;
  }
  .infoBlock {
    flex-direction: column;
  }
  .title {
    margin-bottom: 12px;
  }

  .line {
    margin-top: 32px;
    margin-bottom: 32px;
  }
}
