.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 376px;
}

.icon {
  width: 150px;
  margin-bottom: 24px;
}