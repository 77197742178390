.wrapper {
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 10px;

  /* Default styles */
  width: 40px;
  height: 40px;
  background-color: #fff;
  border: none;
}
