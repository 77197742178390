/*.App {*/
/*  text-align: center;*/
/*}*/

/*.App-logo {*/
/*  height: 40vmin;*/
/*  pointer-events: none;*/
/*}*/

/*@media (prefers-reduced-motion: no-preference) {*/
/*  .App-logo {*/
/*    animation: App-logo-spin infinite 20s linear;*/
/*  }*/
/*}*/

/*.App-header {*/
/*  background-color: #282c34;*/
/*  min-height: 100vh;*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*  font-size: calc(10px + 2vmin);*/
/*  color: white;*/
/*}*/

/*.App-link {*/
/*  color: #61dafb;*/
/*}*/

/*@keyframes App-logo-spin {*/
/*  from {*/
/*    transform: rotate(0deg);*/
/*  }*/
/*  to {*/
/*    transform: rotate(360deg);*/
/*  }*/
/*}*/

*
  font-family: Geologica, sans-serif

ul
  list-style: none
  padding: 0
  margin: 0

a
  text-decoration: none

h3
  margin: 0

:global(.cursorPointer)
  cursor: pointer

:global(.flexCenter)
  display: flex
  justify-content: center
  align-items: center


// СТИЛИ КАРУСЕЛИ
:global(.slick-dots)
  bottom: -33px !important

:global(.slick-dots li.slick-active button:before)
  color: #6172F3 !important

/*.slick-slide {*/
/*  margin: 0 10px;*/
/*}*/
/*.slick-list {*/
/*  margin: 0 -10px !important;*/
/*}*/



/*.slick-slide {*/
/*  padding: 0 8px;*/
/*  box-sizing: border-box;*/
/*}*/

/*.slick-list {*/
/*  margin-left: -8px !important;*/
/*  margin-right: -8px !important;*/
/*}*/



/*.slick-slide {*/
/*  padding: 0 8px;*/
/*  box-sizing: border-box;*/
/*}*/


/*.slick-slider {*/
/*// when you set this, the arrows and dots will be hidden, so you have to handle it via css*!*!*/
/*overflow: hidden;*/
/*}*/
/*.slick-track {*/
/*// removes default auto margin*!*!*/
/*margin: inherit;*/
/*}*/
/*.slick-list {*/
/*// adjust the card-margin*!*!*/
/*width: calc(100% + 20px);*/
/*}*/
/*.item {*/
/*  margin-right: 20px;*/
/*}*/

/*.slick-track {*/
/*  margin-left: 0 !important;*/
/*}*/

/*.slick-slide {*/
/*  padding: 0 12px !important;*/
/*}*/