/* контейнер */
.switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 28px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.checkbox {
}

/* контейнер движущегося элемента */
.slider {
  position: absolute;
  cursor: pointer;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #e9e9e9;
  transition: 0.3s;
}

/* движущий элемент */
.slider::before {
  position: absolute;
  content: "";
  width: 22px;
  height: 22px;
  left: 4px;
  bottom: 3px;
  background-color: #fff;
  transition: 0.3s;
}

input:checked + .slider::before {
  transform: translateX(14px);
}
input:checked + .slider {
  background-color: #739b67;
}

.slider.raunded {
  border-radius: 1000px;
}
.slider.raunded::before {
  border-radius: 1000px;
}
