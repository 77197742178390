.container {
  background-image: url("./assets/bg.png");
  background-position: right;
  background-repeat: no-repeat;
  background-size: 1150px;
  padding-left: 86px;
  padding-right: 86px;
  height: 100%;
  display: flex;
  align-items: center;
  padding-top: 100px;
  padding-bottom: 100px;
}

.form {
  width: 100%;
  max-width: 464px;
  box-shadow: 18px 19px 64.8px 0px #F1F1F1;
  padding: 24px 32px 32px 32px;
  border-radius: 25px;
  background: #FFFFFF;
}

.title {
  font-size: 28px;
  font-weight: 500;
  line-height: 36px;
  margin: 0;
  margin-bottom: 20px;
  color: var(--main-color-text-title);
  opacity: 0.9;
}

.btn {
  height: 56px;
}

.regBtn {
  background: transparent !important;
  height: 56px;
}

.link {
  margin-left: auto;
  margin-right: auto;
}

.error {
  margin: 0;
  margin-bottom: 2px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: var(--error-color-icon);
  text-align: center;
}

@media (max-width: 980px) {
  .container {
    background: transparent;
    padding-left: 0px;
    padding-right: 0px;
    justify-content: center;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 16px;
    padding-right: 16px;
  }
  .form {
    margin-top: 100px;
    margin-bottom: 100px;
  }
}

@media (max-width: 580px) {
  .container {
    background: transparent;
    padding-left: 0px;
    padding-right: 0px;
    justify-content: center;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .form {
    box-shadow: none;
    margin-top: 0px;
    padding: 24px 16px 32px 16px;
  }
  .title {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
  }
}