.container {
  padding: 16px;
  border-radius: 14px;
  background: var(--main-color-bg-widgets);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
}

.icon {
  width: 16px;
}

@media (max-width: 580px) {
  .container {
    width: 36px;
    height: 36px;
    border-radius: 9px;
  }
}