:root {
  --radio-width: 20px;
  --radio-height: 20px;
}

.container {
  display: block;
  position: relative;

  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: var(--radio-height);
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  right: 0;
  height: var(--radio-height);
  width: var(--radio-width);
  background-color: #eee;
  border-radius: 50%;
}
.label {
  color: #070417;
  font-size: 17px;
  font-weight: 500;
  line-height: 20px;
}

/* .container:hover input ~ .checkmark {
  background-color: #d3d3d3;
} */

.container input:checked ~ .checkmark {
  background-color: #739b67;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container input:checked ~ .checkmark:after {
  display: block;
}

.container .checkmark:after {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: white;
}
