.title {
  margin-bottom: 12px;
  color: var(--main-color-text-title);
  font-size: 16px;
  text-align: start;
  font-weight: 500;
  line-height: 20px;
}

.pointBlock {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.pointTitle {
  margin: 0;
  margin-left: 8px;
  color: var(--main-color-text-subtitle);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  width: 100%;
}
