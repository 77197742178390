.tooltip__text {
  font-size: 14px;
  white-space: pre;
}

.tooltip__text_primary {
  color: #739b67;
}

@media (max-width: 767px) {
  .history-chart__tooltip {
    position: fixed !important;
  }
}
