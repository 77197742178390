:root {
  /* ЦВЕТА */
  /* раздел Main */
  --main-color-text-title: #454a3f;
  --main-color-text-subtitle: #656960;
  --main-color-text-secondary: #8b8c88;
  --main-color-text-secondary-2: #585c52;
  --main-color-notification-title: #393e46;
  --main-color-notification-description: #7e7f7a;
  --main-color-text-secondary-unactive: #b5b5b5;
  --main-color-border-icon: #d3d3d3;
  --main-color-bg-secondary: #e9e9e9;
  --main-color-bg-widgets: #f5f5f5;
  --main-color-bg-illustration: #fafafa;
  --main-color-white: #ffffff;
  /* background: linear-gradient(146.49deg, #F1F7FF 2.88%, #DCEFF8 93.47%);
  background: linear-gradient(150.47deg, #EEF0F5 18.08%, #E5EEF2 90.79%); */

  /* раздел Brand */
  --brand-color-primary: #739b67;
  --brand-color-unactive: #b5cda3;
  --brand-color-hover: #d9e3cc;
  --brand-color-disabled: #e8ece3;
  /* раздел Error */
  --error-color-primary: #b72525;
  --error-color-icon: #e64545;
  --error-color-illustration: #f29494;
  --error-color-notification: #fcd8d8;
  --error-color-notification-light: #fff4f4;
  /* раздел Success */
  --success-color-primary: #50d955;
  --success-color-icon: #acf1ae;
  --success-color-illustration: #ecfaed;
  /* раздел Warning */
  --warning-color-primary: #e57616;
  --warning-color-illustration: #fd9031;
  --warning-color-secondary: #fdbc83;
  --warning-color-secondary-light: #fde7d4;
  /* раздел Secondary */
  --secondary-color-1: #deb16e;
  --secondary-color-2: #d6a4a4;
  --secondary-color-3: #9db6db;
  --secondary-color-4: #aca1c3;
  --secondary-color-5: #739b67;
  --secondary-color-6: #e6e279;
  --secondary-color-7: #8bb6a9;
  --secondary-color-8: #e8f1ff;
  /* boxShadow */
  --main-color-box-shadow-1: rgba(69, 74, 63, 0.1);
  --main-color-box-shadow-2: rgba(69, 74, 63, 0.05);
  --main-color-box-shadow-3: rgba(69, 74, 63, 0.06);
  --main-color-box-shadow-4: rgba(69, 74, 63, 0.04);
  --main-color-box-shadow-5: rgba(212, 213, 212, 1);
  --main-color-secondary-box-shadow: rgba(211, 211, 211, 0.25);
  --main-color-secondary-box-shadow-2: rgba(211, 211, 211, 0.62);
  --main-color-secondary-box-shadow-3: rgba(211, 211, 211, 0.54);
}
